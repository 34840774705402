import styled from '@emotion/styled'

import { toRem, toRems } from '@hmn/rtl-net-ui/helpers/theme'

const GRID_COLUMNS = 24 // site value

const GRID_COLUMNS_BASE = 12 // cms value
const GRID_SCALE = GRID_COLUMNS / GRID_COLUMNS_BASE // multiplier

/* eslint-disable quote-props, no-dupe-keys, max-len */
const style = ({ theme }) => ({
    '.fold': {
        display: 'contents'
    }
})

export const Grid = styled.div(({ theme }) => ({
    '& .widgetWrapper': {
        padding: toRems([0, 25])
    },
    [theme.breakpoints.up('md')]: {
        display: 'grid',
        // padding: '0 !important',
        gridTemplateColumns: `repeat(${GRID_COLUMNS}, 1fr)`
    }
}))

export const Cell = styled.div(
    ({ theme, itemCount, position, backgroundColor, noPadding, noMargin, sortByMostRead }) => {
        const gridProps = {}
        if (position) {
            const { x, y, w, h } = position

            gridProps.gridColumnStart = GRID_SCALE * x + 1
            gridProps.gridColumnEnd = GRID_SCALE * (x + w) + 1
            gridProps.gridRowStart = y + 1
            gridProps.gridRowEnd = y + h + 1
            const widthFactor = w < 4 ? 1 : itemCount
            if (itemCount) {
                const columnWidth = w / (widthFactor > w / 2 ? w / 2 : widthFactor)

                const offsetX = (columnWidth - (x % columnWidth)) % columnWidth
                const cellCount = GRID_COLUMNS_BASE / columnWidth

                gridProps.display = 'grid'
                // gridProps.gridGap = toRem(40)
                // gridProps.paddingLeft = `calc(100% * ${offsetX} / ${w})`
                gridProps.gridTemplateColumns = `repeat(auto-fit, calc((100% * ${GRID_COLUMNS_BASE} / ${
                    w - offsetX
                }) / ${cellCount}))`
            }
        }

        return {
            alignContent: 'space-between',
            ...(sortByMostRead && {
                display: 'flex',
                flexDirection: 'column'
            }),
            [theme.breakpoints.up('md')]: {
                '& > div': {
                    padding: !noPadding && toRems([8, 15, 15])
                },
                ...gridProps,
                ...(sortByMostRead && {
                    display: 'flex',
                    flexDirection: 'column',
                    '.cardImage': {
                        width: '5.625rem',
                        minWidth: '5.625rem'
                    }
                }),
                '&.groupWrapper': {
                    '& > div': {
                        padding: !noPadding && 0,
                        overflow: 'hidden'
                    },
                    '& > div > div': {
                        alignSelf: 'start',
                        borderLeft: `1px solid ${theme.colors?.grey400}`,
                        margin: !noMargin && toRems([0, -1, 15])
                    },
                    '& .articleCard': {
                        padding: !noPadding && toRems([0, 15, 20])
                    }
                }
            },
            [theme.breakpoints.down('sm')]: {
                '& > div': {
                    marginBottom: !noMargin && toRem(20)
                }
            },
            ...(backgroundColor && {
                background: backgroundColor,
                '&.groupWrapper': {
                    padding: !noPadding && toRem(15),
                    margin: !noMargin && toRems([10, -15])
                }
            }),
            ...(!backgroundColor && {
                paddingTop: !noPadding && 0
            }),
            ...(backgroundColor === '#080808' && {
                margin: !noMargin && `${toRems([30, -15])} !important`,
                '& .cardContent_title': {
                    color: '#FFF !important'
                },
                '& .articleCard:hover': {
                    '& .cardContent_subtitle': {
                        color: `${theme.colors.text.white} !important`
                    },
                    '& .cardContent_separator': {
                        color: theme.colors.text.white
                    }
                },
                '& .sectionTitle_title': {
                    color: theme.colors.text.inverted
                },
                '& .cardContent_lead': {
                    p: {
                        color: theme.colors.text.inverted
                    }
                }
            }),
            '& .userGeneratedContent': {
                margin: !noMargin && toRems([0, 0, 30]),
                [theme.breakpoints.down('sm')]: {
                    margin: !noMargin && toRems([30, 0])
                }
            },
            '& .newsletter': {
                margin: !noMargin && toRems([0, 0, 30]),
                [theme.breakpoints.down('sm')]: {
                    margin: !noMargin && toRems([30, 0])
                }
            }
        }
    }
)

export default style
