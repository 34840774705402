import React from 'react'
import PropTypes from 'prop-types'

import { withErrorBoundary } from '@hmn/rtl-web-core/components/ErrorBoundary/ErrorBoundary.component'
import { Column } from '@hmn/rtl-web-core/components/layout'
import { usePrevNextContent, usePrevNextScrolling } from '@hmn/rtl-web-core/hooks'

import { ArticleContent, ArticleReadMore } from '@hmn/rtl-net-ui/components/Article/components'
import { PrevNext, prevNextVariants } from '@hmn/rtl-net-ui/components/Navigation/components'

export const getPrevNextHref = (href, isAmp) => {
    if (typeof href !== 'string') {
        return undefined
    }

    return href
}

function PrevNextArticle({ article, Content, currentPage, totalPages, blockList, contentOn }) {
    const { fullArticleHref, next, prev } = usePrevNextContent({
        article,
        Content,
        currentPage,
        totalPages,
        blockList
    })

    const { contentRef } = usePrevNextScrolling()

    return (
        <>
            {contentOn && (
                <Column variant="article.content">
                    <ArticleContent ref={contentRef}>
                        <Content />
                    </ArticleContent>
                </Column>
            )}
            <Column variant="article.prevNext">
                <PrevNext
                    page={currentPage}
                    total={totalPages}
                    previousHref={getPrevNextHref(prev?.href)}
                    previousArticleTitle={prev?.title}
                    nextHref={getPrevNextHref(next?.href)}
                    nextArticleTitle={next?.title}
                    variant={prevNextVariants.ALPHA}
                    sticky
                />
            </Column>
            <Column variant="article.readMore">
                <ArticleReadMore href={fullArticleHref} />
            </Column>
        </>
    )
}

PrevNextArticle.propTypes = {
    article: PropTypes.shape({
        sitemap: PropTypes.shape({
            href: PropTypes.string
        }),
        path: PropTypes.string,
        href: PropTypes.string,
        title: PropTypes.string,
        image: PropTypes.shape({
            id: PropTypes.string
        })
    }),
    Content: PropTypes.elementType,
    currentPage: PropTypes.number,
    totalPages: PropTypes.number,
    // eslint-disable-next-line react/forbid-prop-types
    blockList: PropTypes.arrayOf(PropTypes.object),
    contentOn: PropTypes.bool
}

PrevNextArticle.defaultProps = {
    article: undefined,
    Content: undefined,
    currentPage: undefined,
    totalPages: undefined,
    blockList: undefined,
    contentOn: false
}
export default withErrorBoundary(PrevNextArticle, {
    FallbackComponent: () => null,
    onError(error, componentStack) {
        // eslint-disable-next-line no-console
        console.error('[PrevNextArticle]: ', error, componentStack)
    }
})
