/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/anchor-is-valid */

import { useMemo } from 'react'
import { useUIDSeed } from 'react-uid'
import styled from '@emotion/styled'
import clsx from 'clsx'
import Head from 'next/head'
import Link from 'next/link'
import { usePathname } from 'next/navigation'
import { useRouter } from 'next/router'
import PropTypes from 'prop-types'

import { withErrorBoundary } from '@hmn/rtl-web-core/components/ErrorBoundary/ErrorBoundary.component'
import { useBreakpoints } from '@hmn/rtl-web-core/hooks'

import { ArrowLeftIcon } from '../Icon'
import { PaginationButton } from './components'
import styles from './Pagination.style'

const paginationEnd = (pageTotal, pageChunk, pagesPerChunk, selected) => {
    if (pageTotal <= pageChunk * pagesPerChunk + pagesPerChunk) {
        return pageTotal
    }
    if (pagesPerChunk === 9) {
        return selected > 5 ? selected + 4 : pagesPerChunk
    }
    return selected > 3 ? selected + 3 : pagesPerChunk
}

const paginationStart = (end, pagesPerChunk) => {
    if (end <= pagesPerChunk) {
        return 0
    }
    return end - pagesPerChunk
}

const PaginationStyled = styled.div(props => ({ ...styles(props) }))

function Pagination({
    pageHref,
    pageTotal: pageTotalInput,
    selected,
    paginationColor,
    isSerp,
    serpHref,
    pageName,
    ...rest
}) {
    const [isDesktop] = useBreakpoints('md')
    const uid = useUIDSeed()
    const router = useRouter()

    const pageTotal = Math.ceil(pageTotalInput)
    const pathname = usePathname()

    const { nextUrl, prevUrl, basePath } = useMemo(() => {
        // search page has different pagination params structure
        // so applying this fallback for now to not change other codebase
        if (isSerp && serpHref) {
            return {
                basePath: `${serpHref}`,
                prevUrl: selected > 1 ? `${serpHref}${selected - 1}` : null,
                nextUrl: selected < pageTotal ? `${serpHref}${selected + 1}` : null
            }
        }

        if (pageHref) {
            return {
                basePath: `${pageHref}`,
                prevUrl: selected > 1 ? `${pageHref}?stranica=${selected - 1}` : null,
                nextUrl: selected < pageTotal ? `${pageHref}?stranica=${selected + 1}` : null
            }
        }

        const pathParts = (router?.asPath || '').split('/').filter(Boolean)

        if (!pathParts) {
            return {}
        }

        const { 1: pathPageNumber } = pathParts?.[pathParts.length - 1]?.split('-') || []

        if (pathPageNumber === selected.toString()) {
            pathParts.pop()
        }

        return {
            basePath: `${pathname}`,
            prevUrl: selected > 1 ? `${pathname}?stranica=${selected - 1}` : null,
            nextUrl: selected < pageTotal ? `${pathname}?stranica=${selected + 1}` : null
        }
    }, [router, selected, pageTotal, pageHref, isSerp, serpHref])

    const onButtonClick = (page, e) => {
        e?.target?.blur?.()
        if (page > pageTotal || page < 1) {
            return false
        }

        return false
    }

    if (!pageTotal || !selected) {
        return null
    }

    const pagesPerChunk = isDesktop ? 9 : 6
    const pageChunk = Math.floor(selected / pagesPerChunk)

    const end = paginationEnd(pageTotal, pageChunk, pagesPerChunk, selected)
    const start = paginationStart(end, pagesPerChunk)
    return (
        <>
            <PaginationStyled paginationColor={paginationColor} {...rest}>
                {selected !== 1 && (
                    <PaginationButton
                        id="pagination_left"
                        icon={ArrowLeftIcon}
                        href={prevUrl}
                        onClick={() => onButtonClick(selected - 1)}
                    />
                )}

                <div className="pagination_items">
                    {Array.from({ length: end - start }, (_, index) => {
                        const page = index + start + 1
                        return (
                            <Link
                                key={uid(`${index + page}`)}
                                href={isSerp ? `${serpHref}${page}` : `${basePath}?stranica=${page}`}
                                className={clsx('pagination_item', page === selected && 'isSelected')}
                                aria-label={page}
                                tabIndex={index}
                                onKeyUp={e => onButtonClick(page, e)}
                                onClick={e => onButtonClick(page, e)}>
                                <span className="hidden">{`${(pageName && `${pageName} | `) || ''}Stranica `}</span>
                                {page}
                            </Link>
                        )
                    })}
                </div>

                {selected < pageTotal && (
                    <PaginationButton
                        id="pagination_right"
                        icon={ArrowLeftIcon}
                        rotate={180}
                        href={nextUrl}
                        onClick={() => onButtonClick(selected + 1)}
                    />
                )}
            </PaginationStyled>
            {(prevUrl || nextUrl) && (
                <Head>
                    {prevUrl && <link rel="prev" href={prevUrl} />}
                    {nextUrl && <link rel="next" href={nextUrl} />}
                </Head>
            )}
        </>
    )
}

Pagination.propTypes = {
    pageHref: PropTypes.string,
    selected: PropTypes.number,
    pageTotal: PropTypes.number,
    paginationColor: PropTypes.string,
    isSerp: PropTypes.bool,
    serpHref: PropTypes.string,
    pageName: PropTypes.string
}

Pagination.defaultProps = {
    pageHref: '',
    selected: 1,
    pageTotal: 0,
    paginationColor: undefined,
    isSerp: false,
    serpHref: '',
    pageName: ''
}

export default withErrorBoundary(Pagination, {
    FallbackComponent: () => null,
    onError(error, componentStack) {
        // eslint-disable-next-line no-console
        console.error('[Pagination]: ', error, componentStack)
    }
})
