import styled from '@emotion/styled'
import PropTypes from 'prop-types'

import { withErrorBoundary } from '@hmn/rtl-web-core/components/ErrorBoundary/ErrorBoundary.component'

import { Button } from '../../Button'
import { Icon } from '../../Icon'
import styles from './PaginationButton.style'

const PaginationButtonStyled = styled.div(props => ({ ...styles(props) }))

function PaginationButton({ icon, rotate, onClick, href, ...rest }) {
    if (!icon) {
        return null
    }

    return (
        <PaginationButtonStyled {...rest}>
            <Button
                href={href}
                onClick={onClick}
                iconComponent={<Icon icon={icon} rotate={rotate} color="#ffffff" />}
                isRound
            />
        </PaginationButtonStyled>
    )
}

PaginationButton.propTypes = {
    icon: PropTypes.oneOfType([PropTypes.object, PropTypes.shape({})]),
    rotate: PropTypes.number,
    onClick: PropTypes.func,
    href: PropTypes.string
}

PaginationButton.defaultProps = {
    icon: undefined,
    rotate: 0,
    onClick: undefined,
    href: undefined
}

export default withErrorBoundary(PaginationButton, {
    FallbackComponent: () => null,
    onError(error, componentStack) {
        // eslint-disable-next-line no-console
        console.error('[PaginationButton]: ', error, componentStack)
    }
})
