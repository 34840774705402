import { memo } from 'react'
import PropTypes from 'prop-types'

import { withErrorBoundary } from '@hmn/rtl-web-core/components/ErrorBoundary/ErrorBoundary.component'

function SingleConditionalInsert({ insert, currentIndex }) {
    if (!insert || !insert.elements || typeof currentIndex !== 'number') {
        return null
    }
    const { every = 1, offset = 0, elements } = insert
    const offsetRowIndex = currentIndex + 1 - offset
    const rowMatchesAdPattern = offsetRowIndex % every === 0 && offsetRowIndex >= 0
    const insertIndex = Math.floor((currentIndex - offset) / every)

    return (rowMatchesAdPattern && insertIndex < elements.length && elements[insertIndex]) || null
}

const insertShapePropType = PropTypes.shape({
    every: PropTypes.number,
    offset: PropTypes.number,
    count: PropTypes.number,
    // eslint-disable-next-line react/forbid-prop-types
    elements: PropTypes.arrayOf(PropTypes.object)
})

SingleConditionalInsert.propTypes = {
    insert: insertShapePropType,
    currentIndex: PropTypes.number
}

SingleConditionalInsert.defaultProps = {
    insert: undefined,
    currentIndex: 0
}

const MemoizedSingleInsert = memo(SingleConditionalInsert)
function ConditionalInsert({ insert, currentIndex }) {
    if (!insert) {
        return null
    }
    if ((Array.isArray(insert) && insert.length === 1) || !Array.isArray(insert)) {
        return <MemoizedSingleInsert insert={Array.isArray(insert) ? insert[0] : insert} currentIndex={currentIndex} />
    }
    return (
        insert.map(insertItem => (
            <MemoizedSingleInsert
                key={`insert-${currentIndex}-${insertItem.name}`}
                insert={insertItem}
                currentIndex={currentIndex}
            />
        )) || null
    )
}
ConditionalInsert.propTypes = {
    insert: PropTypes.oneOfType([insertShapePropType, PropTypes.arrayOf(insertShapePropType)]),
    currentIndex: PropTypes.number
}

ConditionalInsert.defaultProps = {
    insert: undefined,
    currentIndex: 0
}
export default withErrorBoundary(memo(ConditionalInsert), {
    FallbackComponent: () => null,
    onError(error, componentStack) {
        // eslint-disable-next-line no-console
        console.error('[ConditionalInsert]: ', error, componentStack)
    }
})
