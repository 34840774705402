/* eslint-disable @typescript-eslint/naming-convention */
import { WebSite } from 'schema-dts'

import { parseReferencableThing } from '@hmn/rtl-web-core/helpers/seo/referenceThing'

import { organization } from './organization'

const PUBLIC_NAME = process.env.NEXT_PUBLIC_APP_NAME_NET
const PUBLIC_URL = process.env.NEXT_PUBLIC_APP_ROOT_NET

export const website: WebSite = {
    '@type': 'WebSite',
    name: PUBLIC_NAME,
    url: PUBLIC_URL,
    '@id': '#net-hr-website',
    publisher: parseReferencableThing(organization),
    potentialAction: {
        '@type': 'SearchAction',
        target: {
            '@type': 'EntryPoint',
            urlTemplate: `${PUBLIC_URL}/pretrazivanje?q={search_term_string}`
        },
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        'query-input': 'required name=search_term_string' // required by google
    }
}
