import React, { useMemo } from 'react'
import styled from '@emotion/styled'
import PropTypes from 'prop-types'

import styles, { Grid } from './LayoutBuilder.style'

const LayoutBuilderStyled = styled.div(props => ({ ...styles(props) }))
function LayoutBuilder({ widgetListWithData, deviceProp }) {
    const widgets = useMemo(() => {
        const renderWidget = ({ Component, props, key, position }) => {
            // Check if component should be wrapped
            // eslint-disable-next-line react/prop-types
            const needWrapping = deviceProp === 'mobile' && props?.richtext
            if (needWrapping) {
                return (
                    <div key={key} className="widgetWrapper">
                        <Component position={position} deviceProp={deviceProp} {...props} />
                    </div>
                )
            }
            return <Component key={key} position={position} deviceProp={deviceProp} {...props} />
        }

        return widgetListWithData.map(item => {
            if (item.type === 'fold') {
                // It's a fold containing multiple items
                return (
                    <div
                        key={`fold-${item.suffix}`}
                        className={`fold fold-${item.suffix}`}
                        data-upscore-zone={`fold-${item.suffix}`}>
                        {item.props.widgets.map(subItem => renderWidget(subItem))}
                    </div>
                )
            }
            // It's a single item
            return renderWidget(item)
        })
    }, [widgetListWithData, deviceProp])

    return (
        <LayoutBuilderStyled>
            <Grid>{widgets}</Grid>
        </LayoutBuilderStyled>
    )
}

LayoutBuilder.propTypes = {
    widgetListWithData: PropTypes.arrayOf(
        PropTypes.shape({
            Component: PropTypes.elementType,
            props: PropTypes.shape({}),
            key: PropTypes.string.isRequired,
            position: PropTypes.shape({}),
            suffix: PropTypes.string, // For fold containers
            contents: PropTypes.arrayOf(PropTypes.shape({})) // For nested components within folds
        })
    ),
    deviceProp: PropTypes.string
}

LayoutBuilder.defaultProps = {
    widgetListWithData: [],
    deviceProp: 'mobile'
}

export default LayoutBuilder
