import { toRem } from '../../../helpers/theme'

const style = ({ theme }) => ({
    '& button, & a': {
        minHeight: 'unset',
        padding: 0,
        color: `${theme.colors.text.inverted} !important`,
        backgroundColor: `${theme.colors.background.inverted} !important`,
        width: toRem(42),
        height: toRem(42)
    },
    '& .btn_icon.btn_icon': {
        // add specificity
        fontSize: toRem(18)
    }
})

export default style
