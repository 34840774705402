import { getSectionColor } from '../../helpers'
import { responsive, toRem, toRems } from '../../helpers/theme'

const style = ({ theme, paginationColor }) => {
    const selectColor = paginationColor || getSectionColor()
    return {
        '&': {
            display: 'flex',
            width: '100%',
            alignItems: 'center',
            ...responsive([
                { justifyContent: 'space-between' },
                { justifyContent: 'space-between' },
                { justifyContent: 'center' },
                { justifyContent: 'center' },
                { justifyContent: 'center' }
            ])
        },
        '& .pagination_items': {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            ...responsive([
                { padding: 0 },
                { padding: 0 },
                { padding: toRems([0, 30]) },
                { padding: toRems([0, 30]) },
                { padding: toRems([0, 30]) }
            ])
        },
        '& .pagination_item': {
            fontWeight: 900,
            fontSize: toRem(16),
            cursor: 'pointer',
            color: theme.colors.primary,
            '.hidden': {
                display: 'inline-block',
                width: '0',
                height: '0',
                overflow: 'hidden'
            }
        },
        '& .pagination_item.isSelected': {
            color: selectColor
        },
        '& .pagination_item+.pagination_item': {
            marginLeft: toRem(15)
        }
    }
}

export default style
