const insertAds = {
    every: 5,
    offset: 0,
    insertAtLeast: 1,
    elements: [
        {
            position: 'Billboard - 1',
            maxAdWidth: 750
        },
        {
            disablePlaceholder: true,
            disableFixedHeight: true,
            position: 'Billboard - 2',
            maxAdWidth: 750
        },
        {
            disablePlaceholder: true,
            disableFixedHeight: true,
            position: 'Billboard - 3',
            maxAdWidth: 750
        },
        {
            disablePlaceholder: true,
            disableFixedHeight: true,
            position: 'Billboard - 4',
            maxAdWidth: 750
        }
    ]
}

// WARNING: slots defined here will be defined without care if the slot is actually displayed in the end
// check any edits with googletag.openConsole()
// slot must be used in markup in order for valid define - load - display sequence of events
// if you wish to define/add a new slot on a page, make sure that it's added both here and in the markup
// eg. <AdSlot {...staticAds.halfpage1} />
const staticAds = {
    halfpage1: {
        alternateBg: true,
        maxAdWidth: '300px',
        position: 'Floating - 1',
        isSliding: true,
        slidingProps: {
            offsetTop: 70,
            contentWidth: '100%',
            offsetBottom: 120
        }
    },
    outOfPage1: {
        desktopOnly: true,
        onePxSlot: true,
        renderOutOfPage: true,
        position: 'Wallpaper - left' // @TODO replace with OOP cms input once available
    },
    outOfPage2: {
        desktopOnly: true,
        onePxSlot: true,
        renderOutOfPage: true,
        position: 'Wallpaper - right' // @TODO replace with OOP cms input once available
    },
    outOfPage3: {
        mobileOnly: true,
        onePxSlot: true,
        renderOutOfPage: true,
        position: 'Floating' // @TODO replace with OOP cms input once available
    },
    interstitial: {
        // mobileOnly: true,
        // onePxSlot: true,
        renderOutOfPage: true,
        position: 'Hardcoded Interstitial'
    },
    anchor: {
        // onePxSlot: true,
        renderOutOfPage: true,
        position: 'Hardcoded Anchor'
    }
}
export { staticAds, insertAds }
